<template>
  <div class="pa-3">
    <v-card>
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="bhxhLogo"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              NHÂN VIÊN THAM GIA BHXH</v-list-item-title
            >
            <v-list-item-subtitle
              >Danh sách nhân viên tham gia BHXH và mức đóng
              BHXH</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-menu key="Removed" rounded="0" offset-y max-height="250px">
            <template v-slot:activator="{ on }">
              <v-btn :loading="loadingExcel" color="#33691E" v-on="on">
                <v-avatar size="30" class="mr-2">
                  <img :src="excelPic" />
                </v-avatar>
                <span style="color: white" class="btn-add">Xuất Excel</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="item in list_year"
                :key="item"
                link
                @click="exportExcelYear(item)"
              >
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
      </v-list-item>
    </v-card>
    <v-card class="mb-1 mt-3">
      <v-card-title class="pa-6 pb-4">
        <div class="black--text">Danh sách BHXH - </div><div style="color: red" class="pl-2 pr-2">{{tongSoNguoi}}</div> <div style="font-size: 14px">Người tham gia</div>
        <v-spacer></v-spacer>
        <div style="width: 600px" class="mr-4">
          <v-row>
            <v-col cols="7">
              <v-text-field
                append-icon="mdi-magnify"
                v-model="search"
                label="Tìm kiếm"
                clearable
                single-line
                hide-details
              ></v-text-field
            ></v-col>
            <v-col cols="5">
              <v-menu
                ref="menu"
                v-model="showDate"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    prepend-icon="mdi-calendar"
                    placeholder="Chọn thời gian"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showDate = false">
                    Hủy
                  </v-btn>
                  <v-btn text color="primary" @click="changeDate()"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :loading="loading"
        class="elevation-1"
        loading-text="Đang tải dữ liệu ..."
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar color="indigo" size="28">
            <img
              v-if="item.url_image"
              :src="imageEndpoint + item.url_image"
              alt="ManhLe"
            />
            <span style="color: white" v-else-if="item && item.name">{{
              item.name.charAt(0).toUpperCase()
            }}</span>
            <v-icon v-else dark>mdi-account</v-icon>
          </v-avatar>
          <span class="ml-3" style="font-size: 16px">{{ item.name }}</span>
        </template>
        <template v-slot:[`item.gioi_tinh`]="{ item }">
          {{ item.gioi_tinh ? "Nam" : "Nữ" }}
        </template>
        <template v-slot:[`item.thoi_gian`]="">
          {{ time }}
        </template>
        <template v-slot:[`item.muc_dong`]="{ item }">
          <b>
            {{new Intl.NumberFormat({ style: "currency" }).format(item.muc_dong.muc_dong)}} Vnđ
          </b>
        </template>
      </v-data-table>
    </v-card>
    <div class="pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="changePage"
        :total-visible="10"
        >></v-pagination
      >
    </div>
  </div>
</template>

<script>
import bhxhLogo from "../../../assets/images/bhxh.png";
import excelPic from "../../../assets/images/excel.png";
import { getDanhSachBaoHiem, exportDanhSachBaoHiemYear } from "@/api/bhxh";
import { debounce } from "lodash";
import { createListYear } from "@/utils/datehandle";
import { saveAs } from "file-saver";
export default {
  data: () => ({
    list_year: createListYear().reverse(),
    imageEndpoint: process.env.VUE_APP_BASE,
    bhxhLogo,
    excelPic,
    date: "",
    showDate: false,
    tableData: [],
    phong_ban_id: null,
    nhom_to_id: null,
    chuc_vu_id: null,
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    search: null,
    loading: false,
    loadingExcel: false,
    time: "",
    tongSoNguoi: 0,
    headers: [
      { text: "Họ tên", value: "name", sortable: false, width: 250 },
      {
        text: "Số sổ BHXH",
        align: "start",
        sortable: false,
        value: "so_so",
      },
      {
        text: "Mã thẻ BHYT",
        align: "start",
        sortable: false,
        value: "ma_the_bhyt",
      },
      {
        text: "Ngày sinh",
        align: "start",
        sortable: false,
        value: "ngay_sinh",
      },
      {
        text: "Địa chỉ",
        align: "start",
        sortable: false,
        value: "dia_chi_hien_tai",
        width: 300
      },
      {
        text: "Chức vụ",
        align: "start",
        sortable: false,
        value: "chuc_vu",
      },
      {
        text: "Số CMT",
        align: "start",
        sortable: false,
        value: "so_cmt",
      },
      // {
      //   text: "Mã số thuế",
      //   align: "start",
      //   sortable: false,
      //   value: "ma_so_thue",
      // },
      {
        text: "Thời gian",
        align: "start",
        value: "thoi_gian",
      },
      {
        text: "Mức đóng",
        align: "start",
        value: "muc_dong",
      },
    ],
  }),
  watch: {
    search: debounce(async function (val) { 
      this.loading = true;
      this.page = 1;
      let data = await getDanhSachBaoHiem({
        perPage: this.itemsPerPage,
        search: val,
        role_id: this.roleId,
        trang_thai: this.trang_thai,
        nhom_to_id: this.nhom_to_id,
        phong_ban_id: this.phong_ban_id,
        chuc_vu_id: this.chuc_vu_id,
        date: this.date,
      });
      this.loading = false;
      this.tongSoNguoi = data.meta.total
      if (data) {
        this.tableData = data.data;
        this.pageCount = data.meta.last_page;
      } else {
        this.tableData = [];
        this.pageCount = 0;
      }
    }, 300),
  },
  mounted() {
    this.getDateTime();
    this.getData();
  },
  methods: {
    async exportExcelYear(year) {
      try {
        this.loadingExcel = true;
        const res = await exportDanhSachBaoHiemYear({
          year,
        });
        saveAs(new Blob([res]), `Bao_hiem_${year}.xlsx`);
      } finally {
        this.loadingExcel = false;
      }
    },
    async getData() {
      this.loading = true;
      let data = await getDanhSachBaoHiem({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
        nhom_to_id: this.nhom_to_id,
        phong_ban_id: this.phong_ban_id,
        chuc_vu_id: this.chuc_vu_id,
        date: this.date,
      });
      this.loading = false;
      this.tongSoNguoi = data.meta.total
      if (data) {
        this.tableData = data.data;
        this.pageCount = data.meta.last_page;
      } else {
        this.tableData = [];
        this.pageCount = 0;
      }
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    changeDate() {
      this.$refs.menu.save(this.date);
      this.page = 1
      this.getDateTime();
      this.getData();
    },

    getDateTime() {
      let timenow = this.date ? new Date(this.date) : new Date();
      let month = timenow.getMonth() + 1;
      let year = timenow.getFullYear();
      this.time = "Tháng " + month + " Năm " + year;
      this.date = this.date ? this.date : year + "-" + month;
    },
    showMucDong(data) {
      let mucDong = data.find((el) => {
        if (el.ket_thuc) {
          return (
            new Date(el.ket_thuc) > new Date(this.date) &&
            new Date(el.bat_dau) <= new Date(this.date)
          );
        } else {
          return (
            new Date(el.bat_dau) <= new Date(this.date) &&
            new Date() > new Date(this.date)
          );
        }
      });
      if (mucDong) {
        let res = new Intl.NumberFormat({ style: "currency" }).format(
          mucDong.muc_dong
        );
        return res + " VnĐ";
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
</style>