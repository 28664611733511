var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-height":"150","max-width":"150","src":_vm.bhxhLogo}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1 black--text"},[_vm._v(" NHÂN VIÊN THAM GIA BHXH")]),_c('v-list-item-subtitle',[_vm._v("Danh sách nhân viên tham gia BHXH và mức đóng BHXH")])],1),_c('v-menu',{key:"Removed",attrs:{"rounded":"0","offset-y":"","max-height":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loadingExcel,"color":"#33691E"}},on),[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.excelPic}})]),_c('span',{staticClass:"btn-add",staticStyle:{"color":"white"}},[_vm._v("Xuất Excel")])],1)]}}])},[_c('v-list',_vm._l((_vm.list_year),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){return _vm.exportExcelYear(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)],1)],1)],1),_c('v-card',{staticClass:"mb-1 mt-3"},[_c('v-card-title',{staticClass:"pa-6 pb-4"},[_c('div',{staticClass:"black--text"},[_vm._v("Danh sách BHXH - ")]),_c('div',{staticClass:"pl-2 pr-2",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.tongSoNguoi))]),_vm._v(" "),_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v("Người tham gia")]),_c('v-spacer'),_c('div',{staticClass:"mr-4",staticStyle:{"width":"600px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","placeholder":"Chọn thời gian","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('v-date-picker',{attrs:{"type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showDate = false}}},[_vm._v(" Hủy ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.changeDate()}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.url_image,"alt":"ManhLe"}}):(item && item.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.name.charAt(0).toUpperCase()))]):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.gioi_tinh",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gioi_tinh ? "Nam" : "Nữ")+" ")]}},{key:"item.thoi_gian",fn:function(){return [_vm._v(" "+_vm._s(_vm.time)+" ")]},proxy:true},{key:"item.muc_dong",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(new Intl.NumberFormat({ style: "currency" }).format(item.muc_dong.muc_dong))+" Vnđ ")])]}}],null,true)})],1),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }